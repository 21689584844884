import './App.css';
import Router from './router/Router';
import posthog from 'posthog-js';
import { PostHogProvider } from 'posthog-js/react';

posthog.init('phc_XfNzdi5pHRTkO8t6bu4EgprF51qbVaftOgsiqgMeabR', {
  api_host: "https://app.posthog.com",
  capture_pageleave: false,
  capture_pageview: true,
  autocapture: false,
});

function App() {
  return (
    <PostHogProvider client={posthog}>
      <div className="App">
        <Router />
      </div>
    </PostHogProvider>
  );
}

export default App;
