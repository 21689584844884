import { lazy, Suspense } from 'react';
import { Route, Routes, Navigate } from "react-router-dom";

const Router = () => {
  const isVariant = window.mood_variant === "variant";
  const Mood1VariantA = lazy(() => import("pages/Mood1VariantA"));
  const Mood1VariantB = lazy(() => import("pages/Mood1VariantB"));
  const Mood2VariantA = lazy(() => import("pages/Mood2VariantA"));
  const Mood2VariantB = lazy(() => import("pages/Mood2VariantB"));
  const Mood3VariantA = lazy(() => import("pages/Mood3VariantA"));
  const Mood3VariantB = lazy(() => import("pages/Mood3VariantB"));
  const Mood4VariantA = lazy(() => import("pages/Mood4VariantA"));
  const Mood4VariantB = lazy(() => import("pages/Mood4VariantB"));
  const Mood4VariantC = lazy(() => import("pages/Mood4VariantC"));
  const Mood5VariantA = lazy(() => import("pages/Mood5VariantA"));
  const Mood5VariantB = lazy(() => import("pages/Mood5VariantB"));
  const Mood6VariantA = lazy(() => import("pages/Mood6VariantA"));
  const Mood6VariantB = lazy(() => import("pages/Mood6VariantB"));
  const Mood7VariantA = lazy(() => import("pages/Mood7VariantA"));
  const Mood7VariantB = lazy(() => import("pages/Mood7VariantB"));
  const Mood8VariantA = lazy(() => import("pages/Mood8VariantA"));
  const Mood8VariantB = lazy(() => import("pages/Mood8VariantB"));
  const Mood9VariantA = lazy(() => import("pages/Mood9VariantA"));
  const Mood9VariantB = lazy(() => import("pages/Mood9VariantB"));
  const Mood10VariantA = lazy(() => import("pages/Mood10VariantA"));
  const Mood10VariantB = lazy(() => import("pages/Mood10VariantB"));
  const Mood11VariantA = lazy(() => import("pages/Mood11VariantA"));
  const Mood11VariantB = lazy(() => import("pages/Mood11VariantB"));
  const Mood12VariantA = lazy(() => import("pages/Mood12VariantA"));
  const Mood12VariantB = lazy(() => import("pages/Mood12VariantB"));
  const Mood13VariantA = lazy(() => import("pages/Mood13VariantA"));
  const Mood13VariantB = lazy(() => import("pages/Mood13VariantB"));
  const Mood14VariantA = lazy(() => import("pages/Mood14VariantA"));
  const Mood14VariantB = lazy(() => import("pages/Mood14VariantB"));
  const FourtyAge = lazy(() => import("pages/FourtyAge"));
  const TwentyAge = lazy(() => import("pages/TwentyAge"));
  const AgeGatePage = lazy(() => import("pages/AgeGatePage"));
  const NoEF = lazy(() => import("pages/NoEF"));
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/mood-1" />} />
      <Route
        path="/mood-1"
        element={
          <Suspense><Mood1VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-2"
        element={
          <Suspense><Mood2VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-3"
        element={
          <Suspense><Mood3VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-4"
        element={
          <Suspense><Mood4VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-5"
        element={
          <Suspense><Mood5VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-6"
        element={
          <Suspense><Mood6VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-7"
        element={
          <Suspense><Mood7VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-8"
        element={
          <Suspense><Mood8VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-9"
        element={
          <Suspense><Mood9VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-10"
        element={
          <Suspense><Mood10VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-11"
        element={
          <Suspense><Mood11VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-12"
        element={
          isVariant ? <Suspense><Mood12VariantB /></Suspense> : <Suspense><Mood12VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-13"
        element={
          <Suspense><Mood13VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-14"
        element={
          <Suspense><Mood14VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-1/variant-a"
        element={
          <Suspense><Mood1VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-1/variant-b"
        element={
          <Suspense><Mood1VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-2/variant-a"
        element={
          <Suspense><Mood2VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-2/variant-b"
        element={
          <Suspense><Mood2VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-3/variant-a"
        element={
          <Suspense><Mood3VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-3/variant-b"
        element={
          <Suspense><Mood3VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-4/variant-a"
        element={
          <Suspense><Mood4VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-4/variant-b"
        element={
          <Suspense><Mood4VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-4/variant-c"
        element={
          <Suspense><Mood4VariantC /></Suspense>
        }
      />
      <Route
        path="/mood-5/variant-a"
        element={
          <Suspense><Mood5VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-5/variant-b"
        element={
          <Suspense><Mood5VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-6/variant-a"
        element={
          <Suspense><Mood6VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-6/variant-b"
        element={
          <Suspense><Mood6VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-7/variant-a"
        element={
          <Suspense><Mood7VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-7/variant-b"
        element={
          <Suspense><Mood7VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-8/variant-a"
        element={
          <Suspense><Mood8VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-8/variant-b"
        element={
          <Suspense><Mood8VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-9/variant-a"
        element={
          <Suspense><Mood9VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-9/variant-b"
        element={
          <Suspense><Mood9VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-10/variant-a"
        element={
          <Suspense><Mood10VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-10/variant-b"
        element={
          <Suspense><Mood10VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-11/variant-a"
        element={
          <Suspense><Mood11VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-11/variant-b"
        element={
          <Suspense><Mood11VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-12/variant-a"
        element={
          <Suspense><Mood12VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-12/variant-b"
        element={
          <Suspense><Mood12VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-13/variant-a"
        element={
          <Suspense><Mood13VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-13/variant-b"
        element={
          <Suspense><Mood13VariantB /></Suspense>
        }
      />
      <Route
        path="/mood-14/variant-a"
        element={
          <Suspense><Mood14VariantA /></Suspense>
        }
      />
      <Route
        path="/mood-14/variant-b"
        element={
          <Suspense><Mood14VariantB /></Suspense>
        }
      />
      <Route
        path="/age-gate"
        element={
          <Suspense><AgeGatePage /></Suspense>
        }
      />
      <Route
        path="/40-age"
        element={
          <Suspense><FourtyAge /></Suspense>
        }
      />
      <Route
        path="/20-age"
        element={
          <Suspense><TwentyAge /></Suspense>
        }
      />
      <Route
        path="/no-ef"
        element={
          <Suspense><NoEF /></Suspense>
        }
      />
    </Routes>
  );
};

export default Router;
